<template>
    <b-card title="Edit Subcription ">
        <validation-observer ref="simpleRules">
            <b-form @submit.prevent>
                <b-row>
                    <!-- Name -->
                    <!-- <b-col cols="12">
                        <b-form-group label="Plane Type" label-for="name">
                            <validation-provider name="Name" rules="required|max:20" #default="{ errors }">
                                <b-input-group class="input-group-merge"
                                    :class="errors.length > 0 ? 'is-invalid' : 'is-valid'">
                                    <b-input-group-prepend is-text>
                                        <feather-icon icon="UserIcon" />
                                    </b-input-group-prepend>

                                    <b-form-input id="name" v-model="item.name"
                                        :state="errors.length > 0 ? false : null" placeholder="Name"  readonly/>
                                   
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->


<b-col cols="12">
  <b-form-group label="Features" label-for="name">
    <validation-provider name="Name" rules="required|max:20" #default="{ errors }">
      <b-input-group class="input-group-merge">
        <b-input-group-prepend is-text>
          <feather-icon icon="UserIcon" />
        </b-input-group-prepend>

        <b-form-input id="name" v-model="item.features"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name" />
      </b-input-group>

    
<b-form-group label="Select Menu">
  <b-form-select 
    v-model="selectedMenuIds"
    :options="subfind.map(menu => ({ value: menu.id, text: menu.name }))"
    multiple
    placeholder="Select one or more options">
  </b-form-select>
</b-form-group>


      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</b-col>

               

           


                    <!-- submit and reset -->
                    <b-col>
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit"
                            @click.prevent="validationForm" variant="primary" class="mr-1">
                            Submit
                        </b-button>
                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary"
                            @click="reset">
                            Reset
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormText,
    BFormDatalist,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
//Database
import { db, storage } from "@/main";
import firebase from "firebase";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

//Vee-Validate 
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
    required,
} from '@validations'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormText,
        BButton,
        BFormDatalist,
        BFormInvalidFeedback,
        BFormValidFeedback,
        BFormFile,
        BFormSelect,
        BFormTextarea,
        BImg,
        ValidationProvider,
        ValidationObserver,
        required,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
              item: {
        menu_id: [],
        name:'',
         // Example array of menu IDs
      },
               subfind: [], // Array to store menu options
    selectedMenuIds: [], // Array to store selected menu ID
    subselectedCategory: [],
        }
    },
     mounted() {
    // Set the initial selected values based on item.menu_id
    this.selectedMenuIds = this.item.menu_id;
  },  watch: {

    'item.menu_id': {
      immediate: true,
      handler(newVal) {
        this.selectedMenuIds = newVal;
      }
    }
  },
    created() {
        this.getItem();
    },
    computed: {
        validation() {
            return this.name.length > 4 && this.name.length < 13
        },
    },
    methods: {
        change(e) {
              this.checkedCategories = e.target.value;
            //   alert(checkedCategories);
          },

getItem() {
    var id = this.$route.params.id;

    db.collection('subfeatures').doc(id).get().then((doc) => {
        if (doc.exists) {
            const data = doc.data();
            console.log("Fetched subfeatures document:", data);
            this.item = data;
            this.selc = data.checkedCategories;
            this.selectedFlavor = parseInt(data.checkedCategories);
            this.previewImg = data.image;
            if (data.sub_id) {
                this.getSubcategoryName(data.sub_id);
            } else {
                console.log("No sub_id found in the document.");
            }
        } else {
            console.log("No such document in subfeatures!");
        }
    }).catch((error) => {
        console.error("Error getting subfeatures document:", error);
    });
},

getSubcategoryName(sub_id) {
    db.collection('subcategory').doc(sub_id).get().then((doc) => {
        if (doc.exists) {
            const subcategory = doc.data();
            console.log("Fetched subcategory document:", subcategory.name);
             this.item.name= subcategory.name; // Store the subcategory name
        } else {
            console.log(`No such document with ID ${sub_id} in subcategories!`);
        }
    }).catch((error) => {
        console.error(`Error getting subcategory document with ID ${sub_id}:`, error);
    });
},


 readmenuforsub() {
    store.dispatch('app/commitActivateLoader');
    this.subfind = []; // Clear previous subfind data
    db.collection("menuforsub")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.subfind.push({
            id: doc.id,
            name: doc.data().name // Ensure 'name' exists in Firestore
          });
        });
        store.dispatch('app/commitDeactivateLoader');
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        store.dispatch('app/commitDeactivateLoader');
      });
  },
      

        reset() {
            this.name = '',
            this.selectedFlavor = '',
            this.description = '',
            this.previewImg = '',
            this.meta_title = '',
            this.meta_description = '',
            this.meta_keywords = '',
            this.profileImg = '',
            this.selectedoptions= null,
            this.selectedCountry = null;
        },

        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    // eslint-disable-next-line
                    // alert('form submitted!')
                    this.submit();
                }
            })
        },

        submit() {
            store.dispatch('app/commitActivateLoader');
             var date = new Date().toLocaleString();//.toISOString().slice(0, 10);
             var id = this.$route.params.id;
                 
                if(this.checkedCategories == undefined){
                  this.checkedCategories = this.selc;
                    alert(this.checkedCategories);
                }

                db.collection("subfeatures")
                .doc(id)
                .update({
                  features: this.item.features,
                    menu_id: this.selectedMenuIds,
                  modified: date,
                })
                                .then((doc) => {
                                 
                                 
                                   
                                
                                            store.dispatch('app/commitDeactivateLoader');
                                            // this.$router.push({ name: 'subfeatures-list' });
                                            this.showNotification('Data Updated Successfully!', 'AlignJustifyIcon', 'success');
                                        
                                
                               
                         
                                })
                      
        },

        showNotification(title, icon, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon,
                    variant,
                },
            })
        },
     
    },
      mounted() {
    this.readmenuforsub(); // Load subcategories after component is mounted
  },
}
</script>